<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectGroup">
                <el-button type="warning" style="width:256px;">
                    {{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in serverGroups" :key="item.value" :command="item"
                        icon="el-icon-coin">
                        {{item.label}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="onClickAddServer"
                style="margin-left: 8px;">新增区服</el-button>
            <el-button type="primary" icon="el-icon-money" @click="onClickGroupMgr">分组管理</el-button>
            <el-button type="success" icon="el-icon-refresh" @click="refreshServerList(1)">刷新</el-button>           
        </el-col>
        <el-col :span="24">
            <el-table :data="tableData" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号" width="90"> </el-table-column>
                <el-table-column prop="name" label="servername" show-overflow-tooltip> </el-table-column>
                <el-table-column prop="title" label="显示名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="stateTagFormatColor(scope.row.state)"
                            :effect="stateTagFormatEffect(scope.row.state)">{{stateTagFormatTxt(scope.row.state)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="index" label="排序" width="90"></el-table-column>
                <el-table-column prop="host" label="IP地址" show-overflow-tooltip></el-table-column>
                <el-table-column prop="auth_port" label="认证端口" show-overflow-tooltip></el-table-column>
                <el-table-column prop="game_port" label="游戏端口" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="排队服务器" width="150">
                    <template slot-scope="scope">
                        <div>{{scope.row.queue_host}} : {{scope.row.queue_port}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="groupname" label="运维分组" show-overflow-tooltip></el-table-column>                
                <el-table-column label="运行" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.online===0" style="color: #FF0000;">离线</span>
                        <span v-if="scope.row.online===1" style="color: #00D000;">在线</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditServer(scope.$index, scope.row)" type="primary"
                            icon="el-icon-edit" size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickBootServer(scope.row)" :type="scope.row.online?'danger':'success'"
                            icon="el-icon-switch-button" size="small">{{scope.row.online?"关服":"启动"}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========区服弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogFormVisible"  @close="onCloseDlgServer" :close-on-click-modal="false" width="900px"
            center>
            <el-form :model="form" label-width="116px">
                <el-form-item label="【运维分组】">
                    <el-select v-model="form.groupid" placeholder="选择分组" style="width: 100%;">
                        <el-option v-for="item in selectGroups" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="【服务器名】">
                    <el-row>
                        <el-col :span="12">                
                            <el-form-item label="name" label-width="60px">
                                <el-input v-model="form.name" placeholder="内部标识名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="显示" label-width="60px">
                                <el-input v-model="form.title" placeholder="显示用名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>                
                <el-form-item label="【区服IP地址】">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="外网" label-width="60px">
                                <el-input v-model="form.host" placeholder="区服外网IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="内网" label-width="60px">
                                <el-input v-model="form.lanhost" placeholder="区服内网IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【区服端口】">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="认证" label-width="60px">
                                <el-input v-model="form.auth_port" placeholder="认证端口"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="游戏" label-width="60px">
                                <el-input v-model="form.game_port" placeholder="游戏端口"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【排队服务器】">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="外网" label-width="60px">
                                <el-input v-model="form.queue_host" placeholder="排队服IP地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="端口" label-width="60px">
                                <el-input v-model="form.queue_port" placeholder="排队服端口"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【状态/排序】">
                    <el-row>
                        <el-col :span="8">        
                            <el-form-item label="状态" label-width="50px">
                                <el-select v-model="form.state" placeholder="请选择区服状态">
                                    <el-option v-for="item in serverStates" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>  
                        </el-col>
                        <el-col :span="8" v-if="7==form.state">
                            <el-form-item label="预开" label-width="50px">
                                    <el-date-picker
                                      v-model="form.preopentime"
                                      type="datetime"
                                      placeholder="显示预开服时间"
                                      style="width: 100%;">
                                    </el-date-picker>
                            </el-form-item>
                        </el-col>                        
                        <el-col :span="8">
                            <el-form-item label="排序" label-width="50px">
                                <el-input v-model="form.index" placeholder="整数排序值,  越小越靠前"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>                
                <el-form-item label="【时间设置】">
                    <el-row>
                        <el-col :span="8">                    
                            <el-form-item label="时区" label-width="50px">
                                <el-input v-model="form.timezone" placeholder="默认东8区"></el-input>
                            </el-form-item>   
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="开服" label-width="50px">
                                <el-date-picker
                                  v-model="form.open_time"
                                  type="datetime"
                                  placeholder="开服时间"
                                  style="width: 100%;"
                                  @change="onOpenTimeChanged">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="当前" label-width="50px">
                                <el-date-picker
                                  v-model="form.server_time"
                                  type="datetime"
                                  placeholder="服务器时间"
                                  @change="onServerTimeChanged"
                                  style="width: 100%;">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【开放注册】">
                    <el-date-picker
                      v-model="form.regtimes"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      style="width: 100%;">
                    </el-date-picker>      
                </el-form-item>
                <el-form-item label="【区服类型】">
                    <el-row>
                        <el-col :span="24">        
                            <el-form-item label="设置" label-width="50px">
                                <el-select v-model="form.istest" placeholder="请选择是否正式服" style="width: 100%;">
                                    <el-option v-for="item in serverTypes" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>  
                        </el-col>
                    </el-row>
                </el-form-item>  
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onCommitUpdateServer">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========区服弹框END====== -->
        <!-- ========分组管理弹框BEGIN====== -->
        <el-dialog title="分组管理" :visible.sync="dialogFormGroupMgr" :close-on-click-modal="false" width="40%" center>
            <el-button type="primary" icon="el-icon-plus" @click="onClickNewGroup">新建分组</el-button>
            <el-table :data="tableGroupData" height="410" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号" width="60"> </el-table-column>
                <el-table-column prop="name" label="组名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mysqlconn" label="数据库连接" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditGroup(scope.row)" type="primary"
                            icon="el-icon-edit" size="small" v-if="0==scope.row.id?false:true">编辑</el-button>
                        <el-button @click.native.prevent="onClickDelGroup(scope.row)" type="danger"
                            icon="el-icon-delete" size="small" v-if="0==scope.row.id?false:true">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="分组名称" :visible.sync="dialogFormGroupEdit" :close-on-click-modal="false" width="30%" center>
            <el-form :model="formGroup">
                <el-form-item label="分组名称" :label-width="formLabelWidth">
                    <el-input v-model="formGroup.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="数据库连接串" :label-width="formLabelWidth">
                    <el-input v-model="formGroup.mysqlconn" placeholder="-uroot -p123456 -h127.0.0.1"></el-input>
                </el-form-item> 
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onCommitUpdateGroup">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogFormGroupEdit = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========分组管理弹框END====== -->
        <el-dialog title="提示" :visible.sync="dialogLoading" width="24%" :close-on-click-modal="false" :show-close="false">
          <span>查询中...</span>
        </el-dialog>
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import config from '../../config.js'
    import tool from '../../tool.js'

    export default {
        data() {
            return {
                dialogLoading: false,
                dialogFormVisible: false,
                dialogFormGroupMgr: false,
                dialogFormGroupEdit: false,
                form: {
                    id: '',
                    name: '',
                    title: '',
                    state: '',
                    index: '',
                    preopentime: '',                 
                    host: '',
                    lanhost: '',
                    auth_port: '',
                    game_port: '',
                    queue_host: '',
                    queue_port: '',
                    timezone: '',
                    opents: '',
                    open_time:'', // 显示用
                    offset: '',
                    server_time:'',// 显示用
                    groupid: '',
                    regtimes:[],//开放注册时间
                    istest:0, // 测试服与否：0默认正式，1测试服
                },
                serverTimeChanged:false,//标识服务器时间有没有修改
                formTitle: '新增区服',
                tableData: [],
                formGroup: {
                    id: '',
                    name: '',
                    mysqlconn: '',
                },
                tableGroupData: [],
                curGroupId: -1, // 当前选择分组(默认全部)
                curGroupLabel: "全部运维分组", // 当前选择分组名
                serverGroups: [],
                serverStates: config.serverStates,
                serverTypes:[{value:0,label:'正式服',tag:'succes'},{value:1,label:'测试服',tag:'primary'}],
                formLabelWidth: '130px',
                tableHeight: document.documentElement.clientHeight-136,
            }
        },
        created: function() {
            this.refreshServerList()
            this.refreshGroupList()
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
            // 新增或编辑区服的分组时，使用可选择的项目
            selectGroups() {
                return this.serverGroups.slice(1)
            },
        },
        methods: {
            // 刷新服务器列表
            refreshServerList(online) {
                this.gameHttp(1001, {
                    groupid: this.curGroupId,
                    online: online,
                }).then((response) => {
                    this.tableData = response.data.list;
                    this.dialogLoading = false;
                });
                // 刷新在线的时候，显示加载中
                if (online) {
                    this.dialogLoading = true;
                }
            },
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(1003, {}).then((response) => {
                    this.tableGroupData = response.data.list
                    // 更新分组的下拉列表
                    this.serverGroups = [{
                        value: -1,
                        label: "全部运维分组"
                    }]
                    for (let i = 0; i < this.tableGroupData.length; i++) {
                        let item = this.tableGroupData[i]
                        this.serverGroups.push({
                            value: item.id,
                            label: item.name
                        })
                    }
                })
            },
            // 选择服务器分组
            onSelectGroup(item) {
                console.log("onSelectGroup=>", item)
                this.curGroupLabel = item.label
                this.curGroupId = item.value
                // 刷新选择结果
                this.refreshServerList()
            },
            // 弹出新增区服表单
            onClickAddServer() {
                this.formTitle = '新增区服'
                this.dialogFormVisible = true
                // 参数重置为初始空
                for (var key in this.form) {
                    this.form[key] = ''
                }
                this.form.istest = 0; // 默认不是测试服
                delete this.form.online // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
                delete this.form.groupname // 忽略不需要提交的字段  
                this.serverTimeChanged = false;
            },
            // 弹出编辑区服表单
            onClickEditServer(idx, row) {
                this.formTitle = '编辑区服'
                this.dialogFormVisible = true
                // 参数拷贝显示
                this.form = JSON.parse(JSON.stringify(row))
                if (row.open_time) {
                    this.form.open_time = new Date( row.open_time );
                }
                if (row.server_time) {
                    this.form.server_time = new Date( row.server_time )
                }
                if (row.preopentime) {
                    this.form.preopentime = new Date( row.preopentime )
                }
                // 开放注册时间
                if (2 == row.regtimes.length) {
                    this.form.regtimes = [new Date(row.regtimes[0]), new Date(row.regtimes[1])];
                }
                delete this.form.online // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
                delete this.form.groupname // 忽略不需要提交的字段
                this.serverTimeChanged = false;
                // 获取服务器时间戳并展示服务器时间
                //this.gameHttp(1013, {}).then((response) => {
                //    let ts = response.data.ts + parseInt(row.offset);
                //    let dtime = tool.formatTs((++ts)*1000);
                //    this.form.offset = dtime;
                //});
            },
            // 响应开服时间变更
            onOpenTimeChanged() {
                this.$confirm('开服时间中途调整，需通知开发进行清库操作！', {
                    type: 'warning'
                });
            },
            // 服务器时间修改
            onServerTimeChanged() {
                this.serverTimeChanged = true;
            },
            // 提交新增/编辑区服
            onCommitUpdateServer() {
                if (!this.form.groupid) {
                    this.$message.error('请指定所属分组！');
                    return;
                }
                for (var key in this.form) {
                    if ('id'==key || 'timezone'==key || 'opents'==key || 'offset'==key || 'open_time'==key 
                    || 'server_time'==key || 'preopentime'==key || 'preopentime'==key|| 'regtimes'==key) { continue; } // 忽略可空的字段
                    if ( 0 == this.form[key].length ) {
                        this.$message.error(key + ' 参数不能为空，请检查确认！');
                        return;
                    }
                }
                // 判断预开服状态需要设置预开服时间
                if (7 == this.form.state && !this.form.preopentime) {
                    this.$message.error(' 预开服状态，需指定预开服时间！');
                    return;                    
                }
                this.dialogFormVisible = false
                // 开服时间&服务器偏移时间转换
                this.form.opents = this.form.open_time ?  Date.parse(this.form.open_time)/1000 : 0;
                this.form.preopentime = this.form.preopentime ? tool.dateFormat(this.form.preopentime ) : 'NULL';
                if (this.serverTimeChanged) { // 无修改的就不用动，否则
                    this.form.offset = this.form.server_time ? Date.parse(this.form.server_time)/1000 : 0;
                    if (this.form.offset) {
                        this.form.offset -=  parseInt(Date.now()/1000);
                    }
                }
                console.log(this.form);                
                // 开放注册时间
                if (this.form.regtimes) {
                    if (2==this.form.regtimes.length) {
                        this.form.regtimes = [
                            tool.dateFormat( this.form.regtimes[0] ),
                            tool.dateFormat( this.form.regtimes[1] )
                        ];                        
                    }
                } else {
                    this.form.regtimes = [];
                }
                // 请求操作
                this.gameHttp(1002, this.form).then((response) => {
                    this.$message.success(response.data.errmsg)
                    this.refreshServerList()
                })
            },
            // 关闭pkg状态刷新
            onCloseDlgServer() {
                if (this.showtimer) {
                    clearInterval(this.showtimer);
                }
            },
            // 状态列的标签格式化显示
            stateTagFormatEffect(statev) {
                return (2 == statev) ? "light" : "dark"
            },
            stateTagFormatTxt(statev) {
                return this.serverStates[statev - 1].label
            },
            stateTagFormatColor(statev) {
                return this.serverStates[statev - 1].tag
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
            ////////////////////////
            ////////////////////////
            onClickGroupMgr() {
                this.dialogFormGroupMgr = true
                this.refreshGroupList()
            },
            // 分组的新增/更新
            onClickNewGroup() {
                this.dialogFormGroupEdit = true
                this.formGroup.id = ''
                this.formGroup.name = ''
            },
            onClickEditGroup(row) {
                this.dialogFormGroupEdit = true
                this.formGroup = JSON.parse(JSON.stringify(row))
            },
            // 分组的删除
            onClickDelGroup(row) {
                this.$confirm('是否删除[ ' + row.name + ' ]分组?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(1005, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshGroupList()
                        }
                    })
                }).catch(() => {})
            },
            // 提交新增/编辑分组
            onCommitUpdateGroup() {
                if (0 == this.formGroup.name.length) {
                    this.$message.error('请输入分组名称！');
                    return;
                }
                if (0 == this.formGroup.mysqlconn.length) {
                    this.$message.error('请输入数据库连接串！');
                    return;
                }                
                this.dialogFormGroupEdit = false
                // 请求操作
                this.gameHttp(1004, this.formGroup).then((response) => {
                    this.$message.success(response.data.errmsg)
                    this.refreshGroupList()
                })
            },
            // 启动服务器
            onClickBootServer(row) {
                let op = row.online?"关服":"启动"
                this.$confirm('是否' + op + '[ ' + row.name + ' ]?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(1092, {
                        boot: row.online?0:1,
                        list: [row.name]
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("提交成功")
                            this.refreshGroupList()
                        }
                    })
                }).catch(() => {})
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    /*导航菜单字体大小*/
    .serverlist {
        font-size: 12px;
    }
</style>
